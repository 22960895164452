/* Container CSS */

.articles {
	position: relative;
	box-sizing: border-box;
	min-height: 700px;
	padding: 40px;
	color: #555;
	font-size: 14px;
	font-family: 'Monda', "PingFang SC", "Microsoft YaHei", sans-serif;
	line-height: 2;
	box-shadow: initial;
	border-radius: initial
}

.articles-wrap {
	position: relative;
	z-index: 98;
	display: block
}

.articles-wrap::after {
	position: absolute;
	top: 20px;
	left: 65px;
	width: 4px;
	height: 100%;
	margin-left: -2px;
	content: " ";
	background: #F5F5F5;
	z-index: -1
}

.archive-move-on {
	position: absolute;
	top: 11px;
	left: 65px;
	width: 10px;
	height: 10px;
	margin-left: -6px;
	opacity: 0.5;
	background: #555;
	border: 1px solid #FFF;
	border-radius: 50%
}

.archive-counter {
	position: relative;
	top: 3px;
	left: 85px
}

.archive-counter .category,
.archive-counter .tag {
	font-style: italic
}

.archive-counter .category {
	color: #E8A258	
}

.archive-counter .tag {
	color: #6FA3EF
}

.articles-wrap .archive-title {
	position: relative;
	margin: 60px 0
}

.articles-wrap .archive-title::before {
	position: absolute;
	left: 65px;
	top: 50%;
	width: 8px;
	height: 8px;
	margin-left: -4px;
	margin-top: -4px;
	content: " ";
	background: #BBB;
	border-radius: 50%
}

.archive-title a {
	cursor: pointer
}

.archive-year {
	margin: 20px 0 15px 0;
	padding: 0;
	color: #555;
	font-size: 20px;
	font-weight: bold;
	font-family: 'Roboto Slab', 'Monda', "PingFang SC", "Microsoft YaHei", sans-serif;
	line-height: 1.5;
	opacity: 0;
	transition: translateX(20px)
}

.articles-wrap .article {
	margin: 30px 0;
	padding-left: 65px
}

.article-header {
	position: relative;
	border-bottom: 1px dashed #CCC;
	opacity: 0
}

.article-header::before {
	position: absolute;
	content: " ";
	left: 0;
	top: 12px;
	width: 6px;
	height: 6px;
	margin-left: -4px;
	background: #BBB;
	border-radius: 50%;
	border: 1px solid #FFF
}

.article-header:hover {
	border-bottom-color: #666
}

.article-header:hover::before {
	background: #222
}

.article-title {
	margin-left: 60px;
	margin-bottom: 0;
	padding-bottom: 0;
	font-size: 16px;
	font-weight: normal;
	font-family: 'Roboto Slab', 'Monda', "PingFang SC", "Microsoft YaHei", sans-serif;
	line-height: inherit;
	border-bottom: none;
	overflow-x: auto;
}

.article-title::after {
	margin-left: 3px;
	opacity: 0.6
}

.article-title a {
	color: #666 !important;
	border-bottom: none;
	text-decoration: none;
	white-space: nowrap;
}

.article-meta {
	position: absolute;
	top: 5px;
	left: 20px;
	font-size: 12px
}


/* Media CSS */

@media only screen and (max-width:1200px) {

}

@media only screen and (max-width:960px) {

	.articles {
		padding: 40px 20px
	}
}

@media only screen and (max-width:720px) {

	.articles {
		min-height: 0;
		padding: 20px 10px
	}

	.archive-year {
		font-size: 18px
	}

	.article-title {
		font-size: 14px
	}

	.article-meta {
		top: 3px
	}
}


/* Dark Mode CSS */

@media (prefers-color-scheme: dark) {

	:root:not([color-scheme]) .articles {
		color: #AAAAAA
	}
	
	:root:not([color-scheme]) .articles-wrap::after {
		background: #444444
	}
	
	:root:not([color-scheme]) .archive-move-on {
		background: #888888;
		border: 1px solid #333333;
		opacity: 1
	}
	
	:root:not([color-scheme]) .articles-wrap .archive-title::before {
		background: #777777
	}
	
	:root:not([color-scheme]) .archive-year {
		color: #AAAAAA
	}
	
	:root:not([color-scheme]) .article-header {
		border-bottom: 1px dashed #444444
	}
	
	:root:not([color-scheme]) .article-header::before {
		background: #777777;
		border: 1px solid #333333
	}
	
	:root:not([color-scheme]) .article-header:hover {
		border-bottom-color: #BBBBBB
	}
	
	:root:not([color-scheme]) .article-header:hover::before {
		background: #BBBBBB
	}
	
	:root:not([color-scheme]) .article-title {
		border: none
	}
	
	:root:not([color-scheme]) .article-title a {
		color: #BBBBBB !important
	}
}

[color-scheme=dark] .articles {
	color: #AAAAAA
}

[color-scheme=dark] .articles-wrap::after {
	background: #444444
}

[color-scheme=dark] .archive-move-on {
	background: #888888;
	border: 1px solid #333333;
	opacity: 1
}

[color-scheme=dark] .articles-wrap .archive-title::before {
	background: #777777
}

[color-scheme=dark] .archive-year {
	color: #AAAAAA
}

[color-scheme=dark] .article-header {
	border-bottom: 1px dashed #444444
}

[color-scheme=dark] .article-header::before {
	background: #777777;
	border: 1px solid #333333
}

[color-scheme=dark] .article-header:hover {
	border-bottom-color: #BBBBBB
}

[color-scheme=dark] .article-header:hover::before {
	background: #BBBBBB
}

[color-scheme=dark] .article-title {
	border: none
}

[color-scheme=dark] .article-title a {
	color: #BBBBBB !important
}